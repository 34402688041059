<template>
  <div>
    <v-card rounded elevation="4">
      <v-skeleton-loader type="image" class="image" />
      <v-skeleton-loader class="title" type="heading, chip" />
      <v-skeleton-loader class="text" type="text@2" />
      <v-skeleton-loader class="actions" type="button@2" />
    </v-card>
  </div>
</template>



<script>
export default {};
</script>

<style lang="scss" scoped>
.image {
  width: 100%;
  height: 200px;
}

.v-skeleton-loader::v-deep {
  .v-skeleton-loader__bone {
    background: var(--v-gray-lighten2) !important;
    border-radius: 3px;
  }
  .v-skeleton-loader__image {
    border-radius: 0px !important;
  }
}

.title.v-skeleton-loader::v-deep {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0 16px;
  align-items: center;
  .v-skeleton-loader__heading {
    width: 143px;
    height: 28px;
  }
  .v-skeleton-loader__chip {
    width: 20px;
    height: 20px;
  }
}

.text.v-skeleton-loader::v-deep {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 12px 16px 40px 16px;
  align-items: center;
  .v-skeleton-loader__text {
    width: 100%;
    height: 10px;
    flex: 0 0 auto;
  }
}

.actions.v-skeleton-loader::v-deep {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px 10px 16px;
  .v-skeleton-loader__chip {
    height: 10px;
    width: 40px;
  }
  .v-skeleton-loader__button {
      height: 38px;
      width: 38px;
      &:last-child {
        margin-left: auto;
      }

  }
}
</style>