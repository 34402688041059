<template>
  <v-card
    elevation="4"
    class="data-table"
    :class="canBeSelected ? 'data-table--selectable' : ''"
  >
    <v-data-table
      calculate-widths
      :headers="headers"
      :items="itemsLoading ? [] : items"
      :items-per-page.sync="itemsPerPage"
      :page.sync="page"
      :search="searchTerm"
      :sort-by="sortBy.value"
      :sort-desc="sortDesc"
      hide-default-footer
      hide-default-header
      class="elevation-0 custom-data-table"
      :server-items-length="itemsTotal"
    >
      <template v-if="itemsLoading" v-slot:body>
        <theme-table-sort-skeleton />
        <theme-table-skeleton-item />
      </template>

      <template v-slot:no-data>
        <data-iterator-no-results-container
          v-if="searchTerm"
          @clearAllFilters="$emit('clearAllFilters')"
          :searchTerm="searchTerm"
        />
        <template v-else>
          <div
            class="d-flex flex-column align-center justify-center mx-auto my-10"
            style="max-width: 560px"
          >
            <div
              class="flex justify-center"
              style="max-width: 64px; margin: auto"
            >
              <themesIllustration/>
            </div>

            <h4 class="mt-6 text-center">{{ $t('message.emptyTable.theme.title') }}</h4>
            <p class="p-1 pt-4 text-center">
              {{ $t('message.emptyTable.theme.description') }}
            </p>
            <v-btn @click="addThemes = true" color="primary" large class="mt-8">
              <v-icon> $plus </v-icon>
              {{ $t('button.addNew') }}
            </v-btn>
          </div>
        </template>
      </template>

      <template v-slot:header="{ isMobile, props, on }" v-if="!itemsLoading">
        <table-custom-sort
          :isMobile="isMobile"
          :props="props"
          :v-on="on"
          :canBeSelected="canBeSelected"
          :allSelected="allSelected"
          @selectAll="handleSelectAll"
          v-on="$listeners"
        />
      </template>

      <template v-slot:item.select="{ item }">
        <div
          class="select-all item-bordered"
          :class="[item.status === 'active' ? 'item-bordered--green' : '']"
        >
          <checkbox
            @change="handleSelectItem(item)"
            :checked="checkedItems.includes(item.name)"
          />
        </div>
      </template>

      <template v-slot:item.title="{ item }">
        <div class="d-flex name-item">
          <v-avatar :size="64" elevation="0" class="mr-6" color="white">
            <v-img v-if="item.img" :src="item.img" />
          </v-avatar>
          <div class="d-flex flex-column">
            <span class="bold p-3">{{ item.title }}</span>
            <span class="p-4 item-desc base--text">
              {{ getDescription(item.description) }}
              <a
                @click="$emit('action-button-read-more', item)"
                class="truncate-text"
                v-if="item.description.length > 100"
              >
                <span class="base--text font-weight-regular">... </span>
                {{ $t('button.readMore') }}
              </a>
            </span>
          </div>
        </div>
      </template>

      <template v-slot:item.version="{ item }">
        <div class="d-flex align-center">
          <v-icon v-if="item.update !== 'available'" color="success" size="16"
            >$alertok</v-icon
          >
          <v-icon v-else color="error" size="16">$alertwarning</v-icon>
          <span class="ml-2 font-weight-bold">{{ item.version }}</span>
        </div>
      </template>

      <template v-slot:item.status="{ item }">
        <status-label
          small
          :value="item.status == 'active' ? $t('message.status.activated') : $t('message.status.disabled')"
          :status="item.status == 'active' ? 'primary' : 'gray'"
        />
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="d-flex actions-row">
          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                small
                v-if="item.status == 'inactive'"
                icon
                @click="$emit('action-button-activate', item)"
              >
                <v-icon>$checkcircle</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('tooltip.instance.theme.activate') }}</span>
          </v-tooltip>
          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on" v-bind="attrs">
                <v-btn
                  small
                  icon
                  :disabled="item.update != 'available'"
                  @click="$emit('action-button-update', item)"
                >
                  <v-icon>$update</v-icon>
                </v-btn>
              </div>
            </template>
            <span>
              {{
                item.update != "available"
                  ? $t('tooltip.instance.theme.updated')
                  : $t('tooltip.instance.theme.update')
              }}
            </span>
          </v-tooltip>
          <v-tooltip
            transition="custom-tooltip"
            open-delay="150"
            bottom
            z-index="99"
            offset-overflow
            nudge-bottom="4px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                small
                icon
                @click="$emit('action-button-delete', item)"
                ><v-icon>$thrash</v-icon></v-btn
              >
            </template>
            <span>{{ $t('tooltip.instance.theme.delete') }}</span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:footer>
        <div>
          <data-iterator-footer
            :page="page"
            :numberOfPages="numberOfPages"
            :possibleItemsPerPage="possibleItemsPerPage"
            :listStyle="listStyle"
            :itemsPerPage="itemsPerPage"
            :itemsTotal="itemsTotal"
            v-on="$listeners"
            :perPageLabel="$t('table.footer.theme')"
          >
          </data-iterator-footer>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import DataIteratorMixin from "../../mixins/DataIteratorMixin";
import CustomTableMixin from "../../mixins/CustomTableMixin";

import DataIteratorFooter from "../dataIterator/DataIteratorFooter.vue";
import TableCustomSort from "../dataIterator/TableCustomSort.vue";
import Checkbox from "../buttons/Checkbox.vue";
import StatusLabel from "../StatusLabel.vue";
import DataIteratorNoResultsContainer from "../dataIterator/DataIteratorNoResultsContainer.vue";

import ThemeTableSortSkeleton from "./ThemeTableSortSkeleton.vue";
import ThemeTableSkeletonItem from "./ThemeTableSkeletonItem.vue";
import themesIllustration from "../illustrations/themes-illustration.vue";

export default {
  components: {
    DataIteratorFooter,
    TableCustomSort,
    Checkbox,
    StatusLabel,
    DataIteratorNoResultsContainer,
    ThemeTableSkeletonItem,
    ThemeTableSortSkeleton,
    themesIllustration,
  },
  data: function () {
    return {
      canBeSelected: true,
    };
  },
  mixins: [DataIteratorMixin, CustomTableMixin],
  props: {
    headers: Array,
    itemsTotal: Number,
    itemsLoading: Boolean,
  },
  methods: {
    getPluginUrl: function (item) {
      return item.toLowerCase().replaceAll(" ", "").replaceAll("-", "");
    },
    getDescription(item) {
      let currentLength = 120;

      for (let i = currentLength; i > 0; i--) {
        if (item.charAt(i - 1) === " ") {
          let result = item.slice(0, i - 1);
          return result + " ";
        }
      }
    },
    clearFilters: function () {
      this.searchTerm = "";
      this.filterLabels = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.item-desc {
  max-width: 412px;
  max-height: 36px;
  display: block;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
}

.name-item {
  width: 535px;
}

.v-btn {
  margin-left: 10px;
}

.v-data-table::v-deep {
  table tbody tr td {
    max-height: 84px;
    height: 84px !important;
    min-height: 84px;
  }
  table tbody tr {
    td:nth-child(2) {
      width: 535px;
    }
  }
}

.item-bordered {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: -24px;
    top: -19px;
    height: calc(100% + 37px);
    width: 4px;
    background-color: var(--v-gray-lighten2);
  }

  &.item-bordered--red {
    &::after {
      background-color: #E71D1D !important;
    }
  }
  &.item-bordered--green {
    &::after {
      background-color: #07C07E !important;
    }
  }
}

.truncate-text {
  font-weight: $font-weight-bold;
  color: var(--v-info-base);
  display: inline;
  justify-content: flex-end;
  margin-left: -4px;
}

.v-avatar {
  border-color: var(--v-gray-lighten2) !important;
  border-style: solid !important;
  border-width: 1px !important;
}
.v-application--is-rtl{

.item-bordered::v-deep{
        &::after {
            right: -24px;
            left: unset;
        }
    }
}
</style>
