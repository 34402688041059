<template>
  <v-container class="px-0 pt-1">
    <v-card
      elevation="4"
      class="data-table"
      :class="canBeSelected ? 'data-table--selectable' : ''"
    >
      <v-data-table
        calculate-widths
        :headers="headers"
        :items="items"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :sort-by.sync="sortByComputed.value"
        :sort-desc.sync="sortDesc"
        hide-default-footer
        hide-default-header
        class="elevation-0 custom-data-table"
        :loading="itemsLoading"
      >
        <template v-slot:header="{ isMobile, props, on }">
          <table-custom-sort
            :isMobile="isMobile"
            :props="props"
            :v-on="on"
            :canBeSelected="canBeSelected"
            :allSelected="allSelected"
            @selectAll="handleSelectAll"
            v-on="$listeners"
          />
        </template>

        <template v-slot:item.select="{ item }">
          <div class="select-all">
            <checkbox
              v-if="canBeChecked"
              @change="handleSelectItem(item)"
              :checked="checkedItems.includes(item.id)"
            />
          </div>
        </template>

        <template v-slot:item.name="{ item }">
          <div
            class="d-flex name-item item-bordered"
            :class="[item.featured ? 'item-bordered--yellow' : '']"
          >
            <v-avatar :size="64" elevation="0" class="mr-6" color="white">
              <v-img :src="item.screenshot_url" />
            </v-avatar>
            <div class="d-flex flex-column">
              <div>
                <span class="bold p-3">{{ item.name }}</span>
                <v-tooltip
                  v-if="item.featured"
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-on="on"
                      v-bind="attrs"
                      color="gray lighten-1"
                      size="16"
                      class="ml-2 primary-on-hover"
                    >
                      $alertcircle
                    </v-icon>
                  </template>
                  <span class="base-text" >{{ $t('tooltip.instance.theme.add.special') }}</span>
                </v-tooltip>
                <div class="featured-chip ml-2" v-if="item.featured">{{ $t('general.featured') }}</div>
              </div>
              <span class="p-4 item-desc mt-1">
                <span
                  v-html="getDescription(item.description)"
                  class="base--text"
                />

                <a
                  v-if="item.description.length > 100"
                  @click="$emit('action-button-read-more', item)"
                  class="info--text font-weight-bold ml-0"
                >
                  <span class="base--text font-weight-regular">... </span>
                  {{ $t('button.readMore') }}
                </a>
              </span>
            </div>
          </div>
        </template>

        <template v-slot:item.version="{ item }">
          <span class="mr-2">{{ item.version }}</span>
        </template>

        <template v-slot:item.actions="{ item }">
          <div class="d-flex actions-row">
            <v-tooltip
              v-if="item.preview_url"
              transition="custom-tooltip"
              open-delay="150"
              bottom
              z-index="99"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  style="min-width: 38px; max-width: 38px"
                  outlined
                  small
                  color="gray"
                  elevation="0"
                  target="_blank"
                  :href="item.type ? item.preview_url : `https://wp-themes.com/${item.slug}`"
                >
                  <v-icon size="18" class="mr-0" color="gray darken-2">
                    $eye
                  </v-icon>
                </v-btn>
              </template>

              <span class="base--text">{{ $t('tooltip.instance.theme.preview') }}</span>
            </v-tooltip>
            <v-tooltip
              transition="custom-tooltip"
              open-delay="150"
              bottom
              z-index="99"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  style="min-width: 38px; max-width: 38px"
                  class="ml-3"
                  color="primary"
                  @click="$emit('action-button-install', item)"
                  small
                  elevation="0"
                >
                  <v-icon
                    v-if="
                      !loading &&
                      !item.hasInstallInProgress() &&
                      !item.hasUpdateInProgress()
                    "
                    size="18"
                    class="mr-0"
                    color="white"
                  >
                    $installIcon
                  </v-icon>

                  <loader
                    v-else
                    color="white"
                    size="24"
                    class="mr-0"
                    :noMargins="true"
                  />
                </v-btn>
              </template>
              <span class="base--text">{{ $t('tooltip.instance.theme.install') }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:item.version="{ item }">
          <div class="d-flex align-center">
            <span class="ml-2">{{ $t('table.field.version', {version: item.version }) }}</span>
          </div>
        </template>

        <template v-slot:item.rating="{ item }">
          <div class="d-flex align-center">
            <v-icon size="20" class="mr-1" :percent="item.rating / 100"
              >$starIcon</v-icon
            >
            <b class="p-4">{{ Math.ceil((item.rating / 10) * 5) / 10 }}</b>
          </div>
        </template>

        <template v-slot:no-data>
          <span v-if="!searchTerm"
            >{{ $t('heading.instance.advanced.theme.add.search') }}</span
          >
          <data-iterator-no-results-container
            v-else
            @clearAllFilters="$emit('clearAllFilters')"
            :searchTerm="searchTerm"
          />
        </template>
        <template v-slot:footer>
          <div>
            <data-iterator-footer
              :page="page"
              :numberOfPages="numberOfPages"
              :possibleItemsPerPage="possibleItemsPerPage"
              :listStyle="listStyle"
              :itemsPerPage="itemsPerPage"
              :itemsTotal="itemsTotal"
              v-on="$listeners"
              :perPageLabel="$t('table.footer.theme')"
            >
            </data-iterator-footer>
          </div>
        </template>
        <template v-if="itemsLoading" v-slot:body>
          <!-- <plugin-table-sort-skeleton /> -->
          <NewThemeTableItemSkeleton :rowsNumber="itemsPerPage" />
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import DataIteratorMixin from "../../mixins/DataIteratorMixin";
import CustomTableMixin from "../../mixins/CustomTableMixin";

import DataIteratorFooter from "../dataIterator/DataIteratorFooter.vue";
import TableCustomSort from "../dataIterator/TableCustomSort.vue";
import Checkbox from "../buttons/Checkbox.vue";
import DataIteratorNoResultsContainer from "../dataIterator/DataIteratorNoResultsContainer.vue";
import NewThemeTableItemSkeleton from "./NewThemeTableItemSkeleton.vue";

export default {
  components: {
    DataIteratorFooter,
    TableCustomSort,
    Checkbox,
    DataIteratorNoResultsContainer,
    NewThemeTableItemSkeleton,
  },
  data: function () {
    return {
      canBeSelected: false,
    };
  },
  mixins: [DataIteratorMixin, CustomTableMixin],
  props: {
    sortBy: [String, Object],
    headers: Array,
    itemsTotal: Number,
    itemsLoading: Boolean,
    searchTerm: String,
  },
  computed: {
    sortByComputed: {
      get: function () {
        return this.sortBy;
      },
      set: function (value) {
        this.$emit("update:sortBy", value);
      },
    },
  },
  methods: {
    getDescription: function (item) {
      let currentLength = 100;

      for (let i = currentLength; i > 0; i--) {
        if (item.charAt(i - 1) === " ") {
          let result = item.slice(0, i - 1);
          return result + " ";
        }
      }
    },
    getPluginUrl: function (item) {
      return item.toLowerCase().replaceAll(" ", "-");
    },
  },
};
</script>

<style lang="scss" scoped>
.item-desc {
  max-width: 412px;
  max-height: 36px;
  display: block;
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
}

.name-item {
  width: 535px;
}

.v-data-table::v-deep {
  table tbody tr td {
    max-height: 84px;
    height: 84px !important;
    min-height: 84px;
  }
}
.v-avatar {
  border-color: var(--v-gray-lighten2) !important;
  border-style: solid !important;
  border-width: 1px !important;
}

.item-bordered::v-deep {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: -24px;
    top: -14px;
    height: calc(100% + 24px);
    width: 4px;
    background-color: transparent;
  }

  &.item-bordered--yellow {
    &::after {
      background-color: #fadd77 !important;
    }
  }
  .primary-on-hover:hover {
    color: var(--v-primary-base) !important;
    transition: color 0.24s ease;

    .v-icon svg,
    svg {
      color: var(--v-primary-base) !important;
      caret-color: var(--v-primary-base) !important;
    }
  }
}

.featured-chip {
  padding: 4px;
  border-radius: 5px;
  font-size: $font-size-xxs;
  line-height: $line-height-xxs;
  font-weight: $font-weight-bold;
  background-color: #fff2d3;
  color: #ec7b13;
  display: inline-block;
}
</style>
