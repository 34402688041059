<template>
  <div class="themes-container">
    <v-tabs-items :value="+addThemes">
      <v-tab-item
        transition="tab-slide"
        reverse-transition="tab-slide-reverse"
        eager
        class="pt-1"
      >
        <template v-if="!itemsLoading && !items.length && !searchTerm">
          <v-container class="px-0">
            <v-row class="mx-0">
              <v-card class="w-100" elevation="4">
                <TableFullScreenMessage
                  :title="$t('message.emptyTable.theme.title')"
                  :desc="$t('message.emptyTable.theme.description')"
                >
                  <template v-slot:image>
                    <themesIllustration/>
                  </template>
                  <template v-slot:action>
                    <v-btn
                      :large="!$vuetify.breakpoint.smAndDown"
                      :small="$vuetify.breakpoint.smAndDown"
                      elevation="0"
                      color="primary"
                      @click="addThemes = true"
                      class="p-2 mx-auto add-new-button"
                    >
                      <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
                        >$plus</v-icon
                      >
                      {{ $vuetify.breakpoint.mobile ? "" : $t('button.addNew') }}
                    </v-btn>
                  </template>
                </TableFullScreenMessage>
              </v-card>
            </v-row>
          </v-container>
        </template>
        <template v-else>
          <v-container class="page-title page-title--advanced">
            <v-row>
              <v-col class="page-title__title-box">
                <h4 class="font-weight-light">{{ $t('heading.instance.advanced.theme.title') }}</h4>
                <list-grid-switch
                  v-if="$vuetify.breakpoint.width > 1400"
                  :listStyle.sync="listStyle"
                ></list-grid-switch>
              </v-col>
              <v-col class="page-title__filter-controls">
                <data-iterator-filter-controls
                  :sortBy="listStyle ? false : sortBy"
                  :sortDesc="sortDesc"
                  :keys="headers"
                  :showSearchTerm="true"
                  :searchTerm="searchTerm"
                  :fullWidthSearch="true"
                  @update:sortBy="changeSortBy"
                  @update:sortDesc="changeSortDesc"
                  @update:searchTerm="handleSearchTermChange"
                ></data-iterator-filter-controls>
                <v-btn
                  :large="!$vuetify.breakpoint.smAndDown"
                  :small="$vuetify.breakpoint.smAndDown"
                  elevation="0"
                  color="primary"
                  class="p-2 add-new-button page-title__add-button"
                  @click="addThemes = true"
                >
                  <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
                    >$plus</v-icon
                  >
                  {{ $vuetify.breakpoint.mobile ? "" : $t('button.addNew') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-container
            v-if="listStyle === 0 || $vuetify.breakpoint.width < 1400"
          >
            <div :class="checkedItems.length > 0 ? 'pb-15' : ''">
              <data-iterator
                :searchTerm="searchTerm"
                :itemsTotal="itemsTotal"
                :items="itemsLoading ? [] : items"
                :listStyle="$vuetify.breakpoint.width < 1400 ? 0 : listStyle"
                :possibleItemsPerPage="[[6, 12, 24]]"
                :itemsPerPage="itemsPerPage"
                :page="page"
                :keys="headers"
                :sortBy="sortBy"
                :sortDesc="sortDesc"
                :sortDisabled="true"
                :loading="itemsLoading"
                @clearAllFilters="handleSearchTermChange('')"
                @update:page="changePage"
                @update:itemsPerPage="changePerPage"
              >
                <template v-slot:loading>
                  <v-row class="">
                    <v-col
                      v-for="(item, key) in 6"
                      :key="key"
                      cols="12"
                      xs="12"
                      sm="6"
                      md="6"
                      xl="4"
                      class="responsive-card"
                    >
                      <theme-card-skeleton />
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:grid="{ items }">
                  <v-col
                    v-for="(item, key) in items"
                    :key="key"
                    cols="12"
                    sm="6"
                    md="4"
                    lg="6"
                    xl="4"
                    class="responsive-card"
                  >
                    <theme-card
                      @checked="handleItemCheck(item)"
                      :checked="checkedItems.includes(item.name)"
                      :item="item"
                      @action-button-activate="showActivateModal"
                      @action-button-update="showUpdateModal"
                      @action-button-delete="showDeleteModal"
                      @action-button-read-more="readMoreActive = item"
                    ></theme-card>
                  </v-col>
                </template>
              </data-iterator>
            </div>
          </v-container>
          <v-container v-else>
            <v-row>
              <v-col cols="12">
                <theme-table
                  :searchTerm="searchTerm"
                  :items="items"
                  :sortBy="sortBy"
                  :headers="headers"
                  :sortDesc="sortDesc"
                  :checkedItems="checkedItems"
                  :possibleItemsPerPage="[[6, 12, 24]]"
                  :itemsLoading="itemsLoading"
                  :itemsPerPage="itemsPerPage"
                  :itemsTotal="itemsTotal"
                  :page="page"
                  @update:checked-items="handleItemCheck"
                  @update:check-all="handleSelectAll"
                  @update:itemsPerPage="changePerPage"
                  @update:page="changePage"
                  @update:sortBy="changeSortBy"
                  @update:sortDesc="changeSortDesc"
                  @action-button-activate="showActivateModal"
                  @action-button-update="showUpdateModal"
                  @action-button-delete="showDeleteModal"
                  @action-button-read-more="readMoreActive = $event"
                  @clearAllFilters="handleSearchTermChange('')"
                />
              </v-col>
            </v-row>
          </v-container>
        </template>

        <theme-select-snackbar
          :items="items"
          :checkedItems="checkedItems"
          @update:check-all="handleSelectAll"
          @mass-action-activate="showMassActivateModal(getCheckedItems())"
          @mass-action-update="showMassUpdateModal(getCheckedItems())"
          @mass-action-remove="showMassDeleteModal(getCheckedItems())"
        />
      </v-tab-item>
      <v-tab-item
        transition="tab-slide"
        reverse-transition="tab-slide-reverse"
        eager
        class="pt-1"
      >
        <template>
          <v-container class="page-title page-title--advanced">
            <v-row>
              <v-col class="page-title__title-box">
                <v-btn @click="addThemes = false" icon small class="mr-2">
                  <v-icon size="24">$arrowleft</v-icon>
                </v-btn>
                <h4 class="font-weight-light">{{ $t('heading.instance.advanced.theme.add.title') }}</h4>
                <list-grid-switch
                  :disabled="!filteredNewItems.length"
                  v-if="$vuetify.breakpoint.width > 1400"
                  :listStyle.sync="listStyle"
                ></list-grid-switch>
              </v-col>
              <v-col class="page-title__filter-controls">
                <data-iterator-filter-controls
                  :disabled="!filteredNewItems.length"
                  :showSearchTerm="false"
                  :sortBy="!listStyle ? newThemes.sortBy : undefined"
                  :keys="!listStyle ? newThemes.keys : undefined"
                  @update:sortBy="newThemes.sortBy = $event"
                ></data-iterator-filter-controls>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="px-0 mb-6">
            <v-row class="mx-0">
              <NewItemSearchCard
                :item="newThemes"
                @update:searchTerm="newThemesHandleSearchTermChange($event)"
              />
            </v-row>
          </v-container>
          <template v-if="!filteredNewItems.length">
            <v-container class="px-0">
              <v-row class="mx-0">
                <v-card
                  class="w-100 d-flex align-center"
                  style="min-height: 360px"
                  elevation="4"
                >
                  <TableFullScreenMessage
                    class="my-auto"
                    maxWidth="410px"
                    :title="$t('message.emptyTable.theme.add.title')"
                    :desc="$t('message.emptyTable.theme.add.description')"
                  >
                    <template v-slot:image>
                      <svg
                        width="73"
                        height="65"
                        viewBox="0 0 73 65"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3.00306 42.5152C-6.69594 33.3411 8.38886 4.28969 29.7723 0.615481C53.7802 -3.51515 70.6222 14.4679 66.4688 35.2124C61.8817 58.102 31.6893 69.5355 23.0629 61.4111C17.9966 56.6415 26.4404 50.7764 20.2102 44.9114C13.9801 39.0464 7.54448 46.7599 3.00306 42.4924V42.5152Z"
                          fill="#F9F9FB"
                        />
                        <path
                          d="M55.1235 5.01999L51.1235 1.01999"
                          stroke="#07C07E"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M51.1235 5.01999L55.1235 1.01999"
                          stroke="#07C07E"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5.12354 39.02L1.12354 35.02"
                          stroke="#07C07E"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                        <path
                          d="M1.12354 39.02L5.12354 35.02"
                          stroke="#07C07E"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                        <path
                          d="M68.1235 17.02C69.7804 17.02 71.1235 15.6768 71.1235 14.02C71.1235 12.3631 69.7804 11.02 68.1235 11.02C66.4667 11.02 65.1235 12.3631 65.1235 14.02C65.1235 15.6768 66.4667 17.02 68.1235 17.02Z"
                          stroke="#07C07E"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                        <path
                          d="M14.7554 13.0453H56.5608C57.9159 13.0453 59 14.2844 59 15.7763V24.02H12V16.105C12 14.4108 13.2422 13.02 14.7554 13.02V13.0453Z"
                          fill="#EEEEF4"
                        />
                        <path
                          d="M56.2446 53.02L14.4392 53.02C13.0841 53.02 12 51.9572 12 50.6774V24.02L59 24.02V50.3738C59 51.827 57.7578 53.02 56.2446 53.02Z"
                          fill="white"
                        />
                        <path
                          d="M57.5781 13.02H14.4219C13.0843 13.02 12 14.0976 12 15.4269V50.6131C12 51.9424 13.0843 53.02 14.4219 53.02H57.5781C58.9157 53.02 60 51.9424 60 50.6131V15.4269C60 14.0976 58.9157 13.02 57.5781 13.02Z"
                          stroke="#B0B0D3"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13 25.02H59"
                          stroke="#B0B0D3"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M20 21.02C21.1046 21.02 22 20.1246 22 19.02C22 17.9154 21.1046 17.02 20 17.02C18.8954 17.02 18 17.9154 18 19.02C18 20.1246 18.8954 21.02 20 21.02Z"
                          stroke="#797997"
                          stroke-width="2"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                        <rect
                          x="18"
                          y="29.02"
                          width="8"
                          height="8"
                          rx="2"
                          stroke="#B0B0D3"
                          stroke-width="2"
                        />
                        <rect
                          x="18"
                          y="41.02"
                          width="8"
                          height="8"
                          rx="2"
                          stroke="#B0B0D3"
                          stroke-width="2"
                        />
                        <rect
                          x="31"
                          y="29.02"
                          width="8"
                          height="8"
                          rx="2"
                          stroke="#B0B0D3"
                          stroke-width="2"
                        />
                        <rect
                          x="31"
                          y="41.02"
                          width="8"
                          height="8"
                          rx="2"
                          stroke="#B0B0D3"
                          stroke-width="2"
                        />
                        <rect
                          x="45"
                          y="29.02"
                          width="8"
                          height="8"
                          rx="2"
                          stroke="#B0B0D3"
                          stroke-width="2"
                        />
                        <rect
                          x="45"
                          y="41.02"
                          width="8"
                          height="8"
                          rx="2"
                          stroke="#B0B0D3"
                          stroke-width="2"
                        />
                        <path
                          d="M46 47.02C49.866 47.02 53 43.886 53 40.02C53 36.154 49.866 33.02 46 33.02C42.134 33.02 39 36.154 39 40.02C39 43.886 42.134 47.02 46 47.02Z"
                          fill="#F9F9FB"
                          fill-opacity="0.6"
                          stroke="#797997"
                          stroke-width="2"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M51 45.02L56 50.02"
                          stroke="#797997"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                      </svg>
                    </template>
                    <template v-slot:action>
                      <v-btn
                        @click="addThemes = false"
                        outlined
                        large
                        block
                        color="gray lighten-3"
                      >
                        <span class="gray--text text--darken-1">{{ $t('button.back') }}</span>
                      </v-btn>
                    </template>
                  </TableFullScreenMessage>
                </v-card>
              </v-row>
            </v-container>
          </template>
          <template v-else>
            <data-iterator
              v-if="listStyle === 0 || $vuetify.breakpoint.lgAndDown"
              :items="filteredNewItems"
              :loading="newThemes.itemsLoading"
              :itemsPerPage="newThemes.itemsPerPage"
              :itemsTotal="newThemes.itemsTotal"
              :keys="newThemes.headers"
              :sortBy="newThemes.sortBy"
              :sortDesc="true"
              :possibleItemsPerPage="[[6, 12, 24]]"
              :page="newThemes.page"
              @update:page="newThemesChangePage"
              @update:itemsPerPage="newThemesChangePerPage"
            >
              <template v-slot:no-data v-if="!newThemes.itemsLoading">
                <template v-if="!newThemes.searchTerm">
                  <div class="text-center gray-text text--darken-2 my-6">
                    {{ $t('message.emptyTable.theme.add.text') }}
                  </div>
                </template>
                <template v-else>
                  <data-iterator-no-results-container
                    @clearAllFilters="newThemes.searchTerm = ''"
                    :searchTerm="newThemes.searchTerm"
                  />
                </template>
              </template>

              <template v-slot:loading>
                <v-row class="mt-0">
                  <v-col
                    v-for="(_, key) in 6"
                    :key="key"
                    cols="12"
                    xs="12"
                    sm="6"
                    md="6"
                    xl="4"
                    class="responsive-card"
                  >
                    <theme-card-skeleton />
                  </v-col>
                </v-row>
              </template>

              <template v-slot:grid="{ items }">
                <v-col
                  v-for="(item, key) in items"
                  :key="key"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="6"
                  xl="4"
                >
                  <new-theme-card
                    @checked="handleItemCheck(item)"
                    :checked="newThemes.checkedItems.includes(item.name)"
                    :canBeChecked="newThemes.canBeChecked"
                    :item="item"
                    @action-button-install="showInstallModal"
                    @action-button-read-more="readMoreActive = item"
                  ></new-theme-card>
                </v-col>
              </template>
            </data-iterator>

            <new-theme-table
              v-else
              :headers="newThemes.headers"
              :items="filteredNewItems"
              :itemsLoading="newThemes.itemsLoading"
              :itemsPerPage="newThemes.itemsPerPage"
              :itemsTotal="newThemes.itemsTotal"
              :page="newThemes.page"
              :possibleItemsPerPage="[[6, 12, 24]]"
              :searchTerm="newThemes.searchTerm"
              :sortBy.sync="newThemes.sortBy"
              :sortDesc="newThemes.sortDesc"
              @update:itemsPerPage="newThemesChangePerPage"
              @update:page="newThemesChangePage"
              @action-button-install="showInstallModal"
              :checkedItems="newThemes.checkedItems"
              @update:checked-items="handleItemCheck"
              @update:check-all="handleSelectAll"
              @update:sortBy="newThemesChangeSortBy"
              @update:sortDesc="newThemesChangeSortDesc"
              @clearAllFilters="newThemes.searchTerm = ''"
              @action-button-read-more="readMoreActive = $event"
              :canBeChecked="newThemes.canBeChecked"
            />
          </template>
        </template>
        <theme-select-snackbar
          :items="items"
          :checkedItems="newThemes.checkedItems"
          @update:check-all="handleSelectAll"
        />
      </v-tab-item>
    </v-tabs-items>

    <ThemeReadMoreModal
      :item="readMoreActive"
      @resetModal="readMoreActive = {}"
      @installItem="showInstallModal"
      @action-button-activate="showActivateModal"
      @action-button-update="showUpdateModal"
      @action-button-delete="showDeleteModal"
    />

    <v-dialog
      transition="custom-dialog-transition"
      v-model="modalOptions.open"
      :persistent="modalOptions.persistent"
      ref="dialog"
    >
      <div
        class="card-overlay"
        v-if="!modalOptions.persistent"
        @click="modalOptions.open = !modalOptions.open"
      />
      <div class="card-overlay" v-else @click="$refs.dialog.animateClick()" />
      <basic-modal
        style="width: 560px"
        :modalOptions="modalOptions"
        @modal-close="modalClose"
        :key="modalRender"
      >
        <template v-slot:custom="{ item }">
          <template v-if="Array.isArray(item)">
            <div class="target-item-box flex-column">
              <div
                v-for="(subitem, index) in item"
                :key="index"
                class="d-flex"
                :class="item.length - 1 !== index ? 'mb-3' : ''"
              >
                <span
                  class="gray--text text--darken-1"
                  v-html="subitem.title || subitem.name"
                ></span>

                <span
                  class="ml-auto"
                  style="min-width: 48px"
                  v-if="subitem.rating"
                >
                  <v-icon
                    size="20"
                    class="mr-1"
                    :percent="subitem.rating / 100"
                  >
                    $starIcon
                  </v-icon>
                  <b class="p-4">{{
                    Math.ceil((subitem.rating / 10) * 5) / 10
                  }}</b>
                </span>
              </div>
            </div>
          </template>

          <div class="target-item-box" v-else-if="Object.keys(item).length">
            <span
              class="gray--text text--darken-1"
              v-html="item.title || item.name"
            ></span>
            <span class="ml-auto" style="min-width: 48px" v-if="item.rating">
              <v-icon size="20" class="mr-1" :percent="item.rating / 100">
                $starIcon
              </v-icon>
              <b class="p-4">{{ Math.ceil((item.rating / 10) * 5) / 10 }}</b>
            </span>
          </div>
        </template>
      </basic-modal>
    </v-dialog>
  </div>
</template>

<script>
// import Api from "@/apis/Api";

import {
  fetchActiveInstanceThemes,
  fetchNewThemes,
  fetchNewThemesFromPackages,
} from "../../../router/fetchData";

import DataIteratorPageMixin from "../../../mixins/DataIteratorPageMixin";
import CustomTablePageMixin from "../../../mixins/CustomTablePageMixin";
import ActionModalMixin from "../../../mixins/ActionModalMixin";
import ThemesActionsMixin from "../../../mixins/ThemesActionsMixin";

import ListGridSwitch from "../../../components/buttons/ListGridSwitch.vue";
import DataIterator from "../../../components/dataIterator/DataIterator.vue";
import ThemeCard from "../../../components/themes/ThemeCard.vue";
import NewThemeCard from "../../../components/themes/NewThemeCard.vue";
import DataIteratorFilterControls from "../../../components/dataIterator/DataIteratorFilterControls.vue";
import ThemeTable from "../../../components/themes/ThemeTable.vue";
import NewThemeTable from "../../../components/themes/NewThemeTable.vue";
import ThemeSelectSnackbar from "../../../components/themes/ThemeSelectSnackbar.vue";
import BasicModal from "@/components/modal/BasicModal";
import ThemeCardSkeleton from "../../../components/themes/ThemeCardSkeleton.vue";
import TableFullScreenMessage from "../../../components/dataIterator/tables/TableFullScreenMessage.vue";
import DataIteratorNoResultsContainer from "../../../components/dataIterator/DataIteratorNoResultsContainer.vue";
import NewItemSearchCard from "../../../components/dataIterator/NewItemSearchCard.vue";
import ThemeReadMoreModal from "../../../components/themes/ThemeReadMoreModal.vue";
import themesIllustration from "../../../components/illustrations/themes-illustration.vue";

export default {
  components: {
    ListGridSwitch,
    DataIterator,
    ThemeCard,
    NewThemeCard,
    DataIteratorFilterControls,
    ThemeTable,
    NewThemeTable,
    ThemeSelectSnackbar,
    BasicModal,
    ThemeCardSkeleton,
    TableFullScreenMessage,
    DataIteratorNoResultsContainer,
    NewItemSearchCard,
    ThemeReadMoreModal,
    themesIllustration,
  },
  mixins: [
    DataIteratorPageMixin,
    CustomTablePageMixin,
    ActionModalMixin,
    ThemesActionsMixin,
  ],
  data: function () {
    return {
      readMoreActive: {},
      fetching: true,
      addThemes: false,

      /**
       * installed themes
       */
      headers: [
        {
          text: "",
          value: "select",
          sortable: false,
        },
        {
          text: 'name',
          align: "start",
          value: "title",
          sortable: true,
          searchable: true,
        },
        {
          text: 'version',
          value: "version",
          sortable: false,
        },
        {
          text: 'status',
          value: "status",
          sortable: true,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "end",
        },
      ],
      sortDesc: false,
      sortBy: {
        text: 'name',
        value: "title",
        sortable: true,
      },
      items: [],
      allItems: [],
      page: 1,
      itemsPerPage: 6,
      itemsLoading: true,
      searchTerm: "",
      itemsTotal: 0,
      checkedItems: [],

      /**
       * new themes
       */
      newThemes: {
        keys: [
          {
            text: 'sort.rated',
            value: "rating",
            sortable: true,
          },
          {
            text: 'sort.recent',
            value: "last_updated",
            sortable: true,
          },
        ],
        headers: [
          {
            text: 'name',
            align: "start",
            value: "name",
            sortable: true,
            searchable: true,
          },
          {
            text: 'rating',
            value: "rating",
            sortable: true,
          },
          {
            text: 'version',
            value: "version",
            sortable: true,
          },
          {
            text: "",
            value: "actions",
            sortable: false,
            align: "end",
          },
        ],
        sortDesc: true,
        sortBy: {
          text: 'rating',
          value: "rating",
          sortable: true,
        },
        items: [],
        allItems: [],
        page: 1,
        itemsPerPage: 6,
        itemsLoading: false,
        searchTerm: "",
        itemsTotal: 0,
        checkedItems: [],
        canBeChecked: false,
      },
      /**
       * modal
       */
      modalRender: 0,
      modalOptions: {},

      applicationId: this.$route.params.id,
    };
  },
  computed: {
    filteredNewItems() {
      return this.newThemes.allItems.filter((item) => {
        return !this.allItems.find((i) => i.name == item.slug);
      });
    },
  },
  mounted() {
    this.$root.$on("theme-item-changed", (theme) => {
      this.updateThemeStatus(theme);
    });
    this.reloadThemes();

    this.reloadNewThemes();
  },
  methods: {
    getCheckedItems() {
      return this.items.filter((item) => {
        return this.checkedItems.includes(item.name);
      });
    },
    updateThemeStatus(theme) {
      for (let [key, item] of this.items.entries()) {
        if (item.name == theme.name) {
          theme.img = item.img;
          this.items[key] = this.transformItem(theme);
          this.items = [...this.items];
          break;
        }
      }
      for (let item of this.newThemes.items) {
        if (item.slug == theme.name || item.slug == theme.slug) {
          item.async_status = theme.async_status;
          this.newThemes.items = [...this.newThemes.items];
          break;
        }
      }
    },
    transformItem(item) {
      if (typeof item.async_status === "undefined") {
        item.async_status = {};
      }
      item.hasInstallInProgress = () => {
        return (
          item.async_status &&
          (item.async_status.install == "pending" ||
            item.async_status.install == "in_progress")
        );
      };
      item.hasUpdateInProgress = () => {
        return (
          item.async_status &&
          (item.async_status.update == "pending" ||
            item.async_status.update == "in_progress")
        );
      };
      return item;
    },
    reloadThemes() {
      fetchActiveInstanceThemes(this.applicationId).then((response) => {
        this.itemsLoading = false;
        if (response.error) {
          // console.error("ERROR: ", response.error);
          return;
        }
        this.allItems = response.data.map((item) => {
          return this.transformItem(item);
        });
        this.fetching = false;
        this.loadThemes();
      });
    },
    loadThemes() {
      this.itemsLoading = true;
      let items = [...this.allItems];
      // filtering
      if (this.searchTerm) {
        items = items.filter((item) => {
          for (const header of this.headers) {
            if (header.searchable) {
              if (
                item[header.value]
                  .toLowerCase()
                  .includes(this.searchTerm.toLowerCase())
              ) {
                return true;
              }
            }
          }
          return false;
        });
      }
      this.itemsTotal = items.length;

      // sorting
      items = items.sort((i1, i2) => {
        if (!isNaN(i1[this.sortBy.value]) && !isNaN(i2[this.sortBy.value])) {
          return (
            (i1[this.sortBy.value] - i2[this.sortBy.value]) *
            (this.sortDesc ? -1 : 1)
          );
        }
        return (
          i1[this.sortBy.value]
            .toString()
            .localeCompare(i2[this.sortBy.value].toString()) *
          (this.sortDesc ? -1 : 1)
        );
      });

      // Get active theme as first item

      items = items.sort(function (x) {
        return x.status === "active" ? -1 : 0;
      });

      // paging
      items = items.slice(
        (this.page - 1) * this.itemsPerPage,
        this.page * this.itemsPerPage
      );
      this.items = items;
      this.itemsLoading = false;
    },
    changePage(page) {
      this.page = page;
      this.loadThemes();
    },
    changePerPage(perPage) {
      this.page = 1;
      this.itemsPerPage = perPage;
      this.loadThemes();
    },
    changeSortBy: function (data) {
      this.page = 1;
      this.sortBy = data;
      this.$nextTick(() => {
        this.loadThemes();
      });
    },
    changeSortDesc: function (data) {
      this.page = 1;
      this.sortDesc = data;
      this.$nextTick(() => {
        this.loadThemes();
      });
    },
    handleSearchTermChange: function (newValue) {
      this.page = 1;
      this.searchTerm = newValue;
      this.loadThemes();
    },

    /**
     * new themes
     */
    reloadNewThemes() {
      // this.newThemes.itemsLoading = true;
      // let query = `page=${this.newThemes.page}&per_page=${this.newThemes.itemsPerPage}`;

      // if (this.newThemes.searchTerm) {
      //   query += `&search_term=${this.newThemes.searchTerm}`;
      // }

      // Api.cached()
      //   .get(`/instaces/onboarding/themes?${query}`)
      //   .then((response) => {
      //     console.log(response);
      //     // this.newThemes.items = response.data.data;
      //     this.newThemes.itemsTotal = response.data.results;
      //     this.newThemes.items = response.data.data.map((item) => {
      //       return this.transformItem(item);
      //     });
      //   })
      //   .finally(() => {
      //     this.page = 1;
      //     this.newThemes.itemsLoading = false;
      //   });
      this.newThemes.itemsLoading = true;
      if (this.newThemes.searchTerm) {
        fetchNewThemes(this.applicationId, this.newThemes.searchTerm).then(
          (response) => {
            this.newThemes.itemsLoading = false;
            if (response.error) {
              // console.error("ERROR: ", response.error);
              return;
            }
            this.newThemes.allItems = response.data.map((item) => {
              return this.transformItem(item);
            });
            this.loadNewThemes();
          }
        );
      } else {
        fetchNewThemesFromPackages(this.applicationId).then((response) => {
          this.newThemes.itemsLoading = false;
          if (response.error) {
            // console.error("ERROR: ", response.error);
            return;
          }
          this.newThemes.allItems = response.data.map((item) => {
            item.featured = true;
            return this.transformItem(item);
          });
          this.loadNewThemes();
        });
      }
    },
    loadNewThemes() {
      this.newThemes.itemsLoading = true;
      let items = [...this.newThemes.allItems];
      this.newThemes.itemsTotal = items.length;

      // sorting

      var sortBy = this.newThemes.sortBy.value;
      var sortDesc = this.newThemes.sortDesc;

      items = items.sort((i1, i2) => {
        if (!isNaN(i1[sortBy]) && !isNaN(i2[sortBy])) {
          return (i1[sortBy] - i2[sortBy]) * (sortDesc ? -1 : 1);
        }
        // console.log({ i1, i2 });
        // return (
        //   i1[sortBy]
        //     .toString()
        //     .localeCompare(i2[sortBy].toString()) * (sortDesc ? -1 : 1)
        // );
      });
      // paging
      items = items.slice(
        (this.newThemes.page - 1) * this.newThemes.itemsPerPage,
        this.newThemes.page * this.newThemes.itemsPerPage
      );
      this.newThemes.items = items;
      this.newThemes.itemsLoading = false;
    },
    newThemesChangePage(page) {
      this.newThemes.page = page;
      this.reloadNewThemes();
    },
    newThemesChangePerPage(perPage) {
      this.newThemes.page = 1;
      this.newThemes.itemsPerPage = perPage;
      this.reloadNewThemes();
    },
    newThemesChangeSortBy: function (data) {
      this.newThemes.page = 1;
      this.newThemes.sortBy = data;
      this.reloadNewThemes();
    },
    newThemesChangeSortDesc: function (data) {
      this.newThemes.page = 1;
      this.newThemes.sortDesc = data;
      this.reloadNewThemes();
    },
    newThemesHandleSearchTermChange: function (newValue) {
      this.newThemes.searchTerm = newValue;
      this.reloadNewThemes();
    },
  },
  watch: {
    searchTerm: function () {
      this.checkedItems = this.checkedItems.filter((item) => {
        return this.items.find((i) => i.name == item);
      });
    },
    addThemes: function (value) {
      if (value) {
        this.checkedItems = [];
        this.newThemes.searchTerm = "";

        this.$nextTick(() => {
          this.reloadNewThemes();
        });
      }
    },
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-window::v-deep {
  overflow: visible;

  .v-window__container.v-window__container--is-active {
    overflow: visible !important;
  }
}
.target-item-box::v-deep {
  border-radius: 6px;
  border: 1px solid var(--v-gray-lighten2);
  display: flex;
  font-size: $font-size-base;
  line-height: $line-height-base;
  padding: 16px;
  background: var(--v-gray-lighten5);
  font-weight: $font-weight-semibold;
  margin-bottom: 8px;

  + form {
    margin-top: 16px;
  }
}
</style>
